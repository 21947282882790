import React, { useState } from "react";
import Introduction from "../sections/Introduction";
import Colouring from "../sections/Colouring";
import Tone from "../sections/Tone";
import Layout from "../sections/Layout";
import Code from "../Code";
import { Container, Heading, Section, Tabs } from "react-bulma-components/full";
import Tabz from "../Tabs";

const PayButton = () => {
  const [Style, SetStyle] = useState("teal");

  function updateStyle(selectedStyle) {
    event.preventDefault();
    SetStyle(selectedStyle);
  }
  return (
    <div>
      <Introduction />
      <Colouring />
      <Tone />
      <Layout />

      <Section>
        <Container>
          <Heading size={4}>Create your button</Heading>
          <Tabs>
            <Tabz
              state={Style}
              onClick={() => updateStyle("teal")}
              label={"teal"}
            />
            <Tabz
              state={Style}
              onClick={() => updateStyle("dark")}
              label={"dark"}
            />
            <Tabz
              state={Style}
              onClick={() => updateStyle("light")}
              label={"light"}
            />
          </Tabs>
          <Code style={Style} />
        </Container>
      </Section>
    </div>
  );
};

export default PayButton;
