import React, { useState } from "react";
import ReactDOM from "react-dom";
import Header from "./components/Header";
import { Columns } from "react-bulma-components/full";
import Sidebar from "./components/sidebar";
import { Route, Link, BrowserRouter as Router } from "react-router-dom";
import PayButton from "./components/routes/PayButton";
import CalculatorLight from "./components/routes/CalculatorLight";

const App = () => {
  return (
    <Router>
      <div>
        <Header />
        <Columns gapless>
          <Columns.Column narrow className="sidebar" style={{ width: 200 }}>
            <Sidebar />
          </Columns.Column>
          <Columns.Column size={10}>
            <Route path="/pay-button" component={PayButton} />
            <Route path="/calculator-light" component={CalculatorLight} />
          </Columns.Column>
        </Columns>
      </div>
    </Router>
  );
};

const AppName = document
  .querySelector("script[data-AppName]")
  .getAttribute("data-AppName");

ReactDOM.render(<App />, document.getElementById(AppName));
