import React from "react";

const MainButton = ({ style, color }) => {
  return (
    <button
      className={`payl8r-checkout-button payl8r-checkout-button--${color}`}
    >
      <div className="payl8r-checkout-button__center">
        Pay with
        <span
          className={`payl8r-checkout-button__logo ${
            color === "light" ? "payl8r-checkout-button__logo--darken" : ""
          }`}
        />
      </div>
    </button>
  );
};

export default MainButton;
