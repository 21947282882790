import React, { useState } from "react";
import MainButton from "./button";
import MainButtonVert from "./buttonVert";
import Highlight from "react-highlight";

const Code = ({ style }) => {
  const [butttonStyle, SetbuttonStyle] = useState("option1");
  return (
    <div>
      <h1>Button Style</h1>
      <form>
        <div className="radio">
          <label htmlFor="option1">
            <input
              type="radio"
              value="option1"
              checked={butttonStyle === "option1"}
              onChange={event => SetbuttonStyle("option1")}
            />
            Pay button
          </label>
        </div>
        <div className="radio">
          <label htmlFor="option2">
            <input
              type="radio"
              value="option2"
              checked={butttonStyle === "option2"}
              onChange={event => SetbuttonStyle("option2")}
            />
            Checkout button
          </label>
        </div>
      </form>

      {butttonStyle === "option1" ? (
        <div>
          <Highlight language="html">
            {`
@import url("https://payl8r.com/frontend/css/checkout-button-style.css");

<button class="payl8r-checkout-button payl8r-checkout-button--${style}">
  <div class="payl8r-checkout-button__center">Pay with
    <span class="payl8r-checkout-button__logo "></span>
  </div>
</button>
      `}
          </Highlight>{" "}
          <MainButton color={style} />
        </div>
      ) : (
        <div>
          <Highlight language="html">
            {`
@import url("https://payl8r.com/frontend/css/checkout-button-style.css");

<button class="payl8r-checkout-button payl8r-checkout-button--${style}">
Checkout
  <div class="payl8r-checkout-button__center">with
    <span class="payl8r-checkout-button__logo payl8r-checkout-button__logo--darken"></span>
  </div>
</button>
      `}
          </Highlight>
          <MainButtonVert color={style} />
          
        </div>
      )}
    </div>
  );
};

export default Code;
