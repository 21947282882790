import React from "react";
import {
  Container,
  Heading,
  Section,
  Content,
  Columns,
  Box
} from "react-bulma-components/full";
import MainButton from "../button";
import MainButtonVert from "../buttonVert";

const Tone = () => {
  return (
    <Section>
      <Container>
        <Content>
          <Heading subtitle size={4}>
            Content
          </Heading>
          <p>
            The tone of voice must balance the real estate the button size has
            on your page.
          </p>
        </Content>
        <Columns>
          <Columns.Column narrow style={{ width: 400 }}>
            <Box>
              <Heading subtitle size={6}>
                Horizontal style with narrow spacing
              </Heading>
              <MainButton color="teal" style="Pay" />
            </Box>
          </Columns.Column>
        </Columns>
        <Columns>
          <Columns.Column narrow style={{ width: 400 }}>
            <Box>
              <Heading subtitle size={6}>
                Vertical style with wide spacing
              </Heading>
              <MainButtonVert color="teal" />
            </Box>
          </Columns.Column>
        </Columns>
      </Container>
    </Section>
  );
};

export default Tone;
