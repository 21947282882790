import React from "react";
import {
  Container,
  Heading,
  Section,
  Content
} from "react-bulma-components/full";

const Introduction = () => {
  return (
    <Section>
      <Container>
        <Content>
          <Heading size={3}>Payl8r button guidelines</Heading>
          <p>
            The following guidelines will help you as a reference when adding a
            payl8r pay button to your website.
          </p>
          <p>
            Payl8r buttons implemented into your site must adhere to our brand
            guidelines and the following;
          </p>
          <ul>
            <li>
              Sizing must be relative to the other payment buttons on the page
            </li>
            <li>
              Colours must adhere to those specified below and not changed
            </li>
            <li>Only styling elements specified below may be modified</li>
          </ul>
        </Content>
      </Container>
    </Section>
  );
};

export default Introduction;
