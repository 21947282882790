import React from "react";
import { Tabs } from "react-bulma-components/full";

const CodeTabs = ({ state, onClick, label }) => {
  return (
    <Tabs.Tab onClick={onClick} className={label === state ? "is-active" : ""}>
      {label}
    </Tabs.Tab>
  );
};

export default CodeTabs;
