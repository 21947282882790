import React from "react";
import { Container, Columns } from "react-bulma-components/full";
import Logo from "../assets/logo.svg";

const Header = () => {
  return (
    <header className="header">
      <Columns>
        <Columns.Column narrow style={{ width: 250 }}>
          <img src={Logo} className="header__logo" />
        </Columns.Column>
      </Columns>
    </header>
  );
};

export default Header;
