import React from "react";
import {
  Container,
  Heading,
  Section,
  Content,
  Columns
} from "react-bulma-components/full";
import Browser from "../../assets/images/browserone.jpg";
import BrowserTwo from "../../assets/images/browsertwo.jpg";

const Layout = () => {
  return (
    <Section>
      <Container>
        <Content>
          <Heading subtitle size={4}>
            Layout
          </Heading>
          <p>
            When putting a payl8r button onto your page please make sure it fits
            in with the tone of the rest of the page
          </p>
        </Content>
        <Columns>
          <Columns.Column>
            <img src={Browser} />
          </Columns.Column>
          <Columns.Column>
            <img src={BrowserTwo} />
          </Columns.Column>
        </Columns>
      </Container>
    </Section>
  );
};

export default Layout;
