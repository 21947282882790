import React from "react";
import {
  Container,
  Heading,
  Section,
  Content
} from "react-bulma-components/full";
import MainButton from "../button";

const Colouring = () => {
  return (
    <Section>
      <Container>
        <Content>
          <Heading subtitle size={4}>
            Colouring
          </Heading>
          <p>We offer 3 colouring styles for our pay buttons</p>
        </Content>

        <table className="table is-bordered is-striped is-fullwidth">
          <thead>
            <tr>
              <th>Description</th>
              <th>Color</th>
              <th>Button</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Default button</td>
              <td>Teal</td>
              <td>
                <MainButton color="teal" style="Pay" />
              </td>
            </tr>
            <tr>
              <td>Dark button</td>
              <td>Black</td>
              <td>
                <MainButton color="dark" style="Pay" />
              </td>
            </tr>
            <tr>
              <td>Light button</td>
              <td>White</td>
              <td>
                <MainButton color="light" style="Pay" />
              </td>
            </tr>
          </tbody>
        </table>
      </Container>
    </Section>
  );
};

export default Colouring;
