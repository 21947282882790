import React from "react";
import {
  Container,
  Heading,
  Section,
  Content
} from "react-bulma-components/full";
import Highlight from "react-highlight";

const CalculatorLight = () => {
  return (
    <div>
      <Section>
        <Container>
          <Content>
            <Heading size={3}>Calculator Widget</Heading>
            <p>
              Our Payl8r calculator widget enables you to show a products cost
              spread over a 12 month period with Payl8r. This is usually best
              placed on a items product page, the steps below will allow you to
              implement into your site.
            </p>
          </Content>
        </Container>
      </Section>

      <Section>
        <Container>
          <Heading size={4}>Place the app on your page</Heading>
          <p>This is where the app will be rendered to you inside you dom</p>
          <Highlight language="html">{`   
     <div id="pl-calculator-light-app"></div>

`}</Highlight>
        </Container>
      </Section>

      <Section>
        <Container>
          <Heading size={4}>Set the price</Heading>
          <p>Set the price object data to your product price</p>
          <Highlight language="js">{`   
    const setPrice = 19.99;
    document.plCalcPrice = {
        Price: setPrice
    };

`}</Highlight>
        </Container>
      </Section>

      <Section>
        <Container>
          <Heading size={4}>Initiate the app</Heading>
          <p>
            Place this script after the dom element has been placed to
            initialize the app
          </p>
          <Highlight language="html">{`   
    <script
        src="https://assets.payl8r.com/js/pl-calculator-light-app.js"
        id="pl-calculator-light-app-script"
        data-theme="spread"
    />;

`}</Highlight>
        </Container>
      </Section>

      <Section>
        <Container>
          <Heading size={4}>Rerendering the app</Heading>
          <p>
            If there's ever a case where the price would need to update eg
            quantity, you will need to force a rerender, you can do this by
            calling the below method
          </p>
          <Highlight language="js">{`   
   document.plCalcPrice.rerender(
       document.plCalcPrice.Price = 
       )

`}</Highlight>
        </Container>
      </Section>
    </div>
  );
};

export default CalculatorLight;
