import React from "react";
import { NavLink } from "react-router-dom";

const Sidebar = () => {
  return (
    <ul className="sidebar">
      <li>
        <NavLink to="/pay-button" activeClassName="active">
          Pay-button
        </NavLink>
      </li>
      <li>
        <NavLink to="/calculator-light" activeClassName="active">
          Calculator Light
        </NavLink>
      </li>
    </ul>
  );
};

export default Sidebar;
